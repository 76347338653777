import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaEdit, FaTrash } from "react-icons/fa";

// Mock data for demonstration purposes
const fakeStaffData = [
  { id: 1, name: "John Doe", phone: "(555) 123-4567", email: "john.doe@example.com", hoursWorked: 40 },
  { id: 2, name: "Jane Smith", phone: "(555) 987-6543", email: "jane.smith@example.com", hoursWorked: 35 },
];

export default function StaffManagement() {
  const [staff, setStaff] = useState(fakeStaffData);
  const [formData, setFormData] = useState({ name: "", phone: "", email: "", hoursWorked: "" });
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode) {
      // Update existing staff member
      const updatedStaff = [...staff];
      updatedStaff[editIndex] = { ...updatedStaff[editIndex], ...formData };
      setStaff(updatedStaff);
      setEditMode(false);
      setEditIndex(null);
    } else {
      // Add new staff member
      setStaff([...staff, { id: Date.now(), ...formData }]);
    }
    setFormData({ name: "", phone: "", email: "", hoursWorked: "" });
  };

  const handleEdit = (index) => {
    setEditMode(true);
    setEditIndex(index);
    setFormData(staff[index]);
  };

  const handleDelete = (index) => {
    setStaff((prevStaff) => prevStaff.filter((_, i) => i !== index));
  };

  return (
    <Container fluid className="p-3">
      <Row className="mb-4">
        <Col>
          <h2>Staff Management</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-end">
              <Col md={3}>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={3}>
                <Form.Control
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={3}>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  type="number"
                  name="hoursWorked"
                  placeholder="Hours Worked"
                  value={formData.hoursWorked}
                  onChange={handleInputChange}
                  required
                />
              </Col>
              <Col md={1}>
                <Button type="submit">{editMode ? "Update" : "Add"}</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Hours Worked</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {staff.map((staffMember, index) => (
                <tr key={staffMember.id}>
                  <td>{staffMember.name}</td>
                  <td>{staffMember.phone}</td>
                  <td>{staffMember.email}</td>
                  <td>{staffMember.hoursWorked}</td>
                  <td>
                    <Button variant="info" size="sm" onClick={() => handleEdit(index)}><FaEdit /></Button>{" "}
                    <Button variant="danger" size="sm" onClick={() => handleDelete(index)}><FaTrash /></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
