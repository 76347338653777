import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/esm/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/esm/Col";
import { ApiUrl } from "../../utilities/getApiUrl";

export default function UserInfo() {
  const { getAccessTokenSilently } = useAuth0();
  const [orgName, setOrgName] = useState("loading...");
  const [newOrgName, setNewOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [invalidId, setInvalidId] = useState(false);
  useEffect(() => {
    const getUserData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "gkplus",
            scope: "all",
          },
        });
        console.log(accessToken);
        const userData = await fetch(`${ApiUrl()}/api/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((r) => r.json());
        console.log(userData);
        setOrgName(userData.OrgName);
        setOrgId(userData.OrgId);
      } catch (e) {
        console.log(e);
      }
    };
    getUserData();
  }, [getAccessTokenSilently]);

  const saveChanges = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: "gkplus",
        scope: "all",
      },
    });
    const res = await fetch(`${ApiUrl()}/api/user`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        orgName: newOrgName,
        orgId: orgId,
      }),
    }).then(async (res) => {
      if (res.ok) {
        setOrgName(newOrgName);
      } else if (res.status === 400) {
        setInvalidId(true);
      }
      const parsedRes = await res.json();
      console.log(parsedRes);
      setOrgId(parsedRes.OrgId);
    });
  };

  if (orgName == "") {
    return (
      <Modal show>
        <Modal.Header>
          <Modal.Title>Welcome To Field Manager Pro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <b>Lets Get Setup!</b>
            </Col>
          </Row>
          <Row>
            <Col>
              If you do not have an orginization enter the name here
              <Form.Control
                disabled={orgId ? true : false}
                placeholder="pitchers paradise"
                onChange={(e) => setNewOrgName(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              If you already have an orginization enter the id here
              <Form.Control
                disabled={newOrgName ? true : false}
                placeholder="c444781c-6f9e-11ee-b962-0242ac120002"
                onChange={(e) => setOrgId(e.target.value)}
                isInvalid={invalidId}
              />
              <Form.Control.Feedback type="invalid">
                Id was not found, if you are a new org please enter a name.
              </Form.Control.Feedback>
            </Col>
          </Row>
          <Row></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={newOrgName === "" && orgId === ""}
            variant="primary"
            onClick={saveChanges}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <div>
        {orgName}
      </div>
    );
  }
}
