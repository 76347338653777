import Table from "react-bootstrap/Table";
import CreateTaskForm from "../tasks/CreateTaskForm";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiUrl } from "../../utilities/getApiUrl";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

export default function ProjectTasksList() {
  const { getAccessTokenSilently } = useAuth0();
  const [tasks, setTasks] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const getTasksData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "gkplus",
            scope: "all",
          },
        });
        console.log(accessToken);
        const tasksData = await fetch(`${ApiUrl()}/api/task?projectId=${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then((r) => r.json());
        console.log(tasksData);
        setTasks(tasksData);
      } catch (e) {
        console.log(e);
      }
    };
    getTasksData();
  }, [getAccessTokenSilently, id]);

  const addTasks = (task) => {
    setTasks([task, ...tasks]);
  };
  return (
    <>
      <CreateTaskForm addTask={addTasks}></CreateTaskForm>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Time</th>
            <th>Assigned to</th>
            <th>Notes</th>
            <th>Progress</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((t) => {
            return (
              <tr key={t.id}>
                <td>
                  <a href="taskidhere">{t.name}</a>
                </td>
                <td>{t.time}</td>
                <td>{t.assigned_to}</td>
                <td>{t.notes}</td>
                <td>{t.progress}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
