import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiUrl } from "../../utilities/getApiUrl";
import "./CreateProjectForm.css"; // Import the CSS file

export default function CreateProjectForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const { projects } = props; // Assuming projects is passed as a prop

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (!startDate) {
      errors.startDate = "Start Date is required";
    }
    if (!endDate) {
      errors.endDate = "End Date is required";
    }
    if (startDate > endDate) {
      errors.startDate = "Start Date must be before End Date";
    }
    if (endDate < startDate) {
      errors.endDate = "End Date must be after Start Date";
    }
  
    // Check if the name is unique
    const duplicateName = projects.some(project => project.Name.toLowerCase() === name.toLowerCase());
    if (duplicateName) {
      errors.name = "Project name must be unique";
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const saveChanges = async () => {
    if (validateForm()) {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: "gkplus",
          scope: "all",
        },
      });
      try {
        const res = await fetch(`${ApiUrl()}/api/project`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            name,
            startDate,
            endDate,
            notes,
          }),
        });
        if (res.ok) {
          const parsedRes = await res.json();
          props.setCreatingProject(false);
          props.addProject({
            Name: name,
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
            Notes: notes,
            Id: parsedRes.Id,
          });
        } else {
          console.error("Failed to create project");
        }
      } catch (error) {
        console.error("Error creating project:", error);
      }
    }
  };

  return (
    <Form className="create-project-form">
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter project name"
            onChange={(e) => setName(e.target.value)}
            isInvalid={!!formErrors.name}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Start Date</Form.Label>
          <br />
          <DatePicker
            showIcon
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            className="form-control date-picker"
            dateFormat="dd/MM/yyyy"
            isInvalid={!!formErrors.startDate}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.startDate}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>End Date</Form.Label>
          <br />
          <DatePicker
            showIcon
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="form-control date-picker"
            dateFormat="dd/MM/yyyy"
            isInvalid={!!formErrors.endDate}
          />
          <Form.Control.Feedback type="invalid">
            {formErrors.endDate}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col}>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => setNotes(e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Col>
          <Button onClick={saveChanges} className="save-button">
            Save Project
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
