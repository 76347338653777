import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiUrl } from "../../utilities/getApiUrl";
import Collapse from "react-bootstrap/Collapse";
import { useParams } from "react-router-dom";

export default function CreateTaskForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [time, setTime] = useState(0);
  const [assignedTo, setAssignedTo] = useState("");
  const [notes, setNotes] = useState("");
  const [progress, setProgress] = useState(0);

  const clearForm = () => {
    setName("")
    setTime(0)
    setAssignedTo("")
    setNotes("")
    setProgress("")
  }

  let { id } = useParams();
  const saveChanges = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: "gkplus",
        scope: "all",
      },
    });
    await fetch(`${ApiUrl()}/api/task`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
        projectId: id,
        time,
        assignedTo,
        notes,
        progress,
      }),
    })
      .then(async (res) => {
        const parsedRes = await res.json();
        props.addTask(parsedRes);
        setFormOpen(false);
        clearForm()
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button
        variant="success"
        className="m-3"
        onClick={() => setFormOpen(true)}
      >
        Create Task
      </Button>
      <Collapse in={formOpen}>
        <div>
          <Form className="m-3">
            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter task name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Time</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter time"
                    onChange={(e) => setTime(Number(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Assigned To</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Assigned To"
                    onChange={(e) => setAssignedTo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Progress</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Progress"
                    onChange={(e) => setProgress(Number(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col></Col>
            </Row>
            <Row>
              <Col sm={9}>
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={saveChanges}>Save Task</Button>
                <Button
                  variant="danger"
                  className="m-3"
                  onClick={() => setFormOpen(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Collapse>
    </>
  );
}
