import Table from "react-bootstrap/Table";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiUrl } from "../../utilities/getApiUrl";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import CreateBudgetForm from "../budget/CreateBudgetForm";

export default function ProjectBudgetList() {
  const { getAccessTokenSilently } = useAuth0();
  const [budgets, setBudgets] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    const getBudgetData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "gkplus",
            scope: "all",
          },
        });
        console.log(accessToken);
        const budgetData = await fetch(
          `${ApiUrl()}/api/budget?projectId=${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        ).then((r) => r.json());
        console.log(budgetData);
        setBudgets(budgetData);
      } catch (e) {
        console.log(e);
      }
    };
    getBudgetData();
  }, [getAccessTokenSilently, id]);

  const addBudget = (budget) => {
    setBudgets([budget, ...budgets]);
  };

  const totalBudget = budgets?.reduce(
    (total, budget) => total + budget.amount,
    0
  );

  return (
    <>
      <CreateBudgetForm addBudget={addBudget}></CreateBudgetForm>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {budgets?.map((b) => {
            return (
              <tr key={b?.id}>
                <td>
                  <a href="taskidhere">{b?.name}</a>
                </td>
                <td>{b?.type}</td>
                <td>${b?.amount}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="2">Total</td>
            <td>${totalBudget}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}
