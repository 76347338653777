import Table from "react-bootstrap/Table";

export default function ProjectInventoryList() {
    return (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Item</th>
              <th>Amount</th>
              <th>Supplier</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>White Marble Chips</td>
              <td>1 Ton</td>
              <td>Wholesale Rocks</td>
              <td>$450</td>
            </tr>
            <tr>
              <td>grass seed</td>
              <td>65 cubic yards</td>
              <td>grass supplies plus</td>
              <td>$5000</td>
            </tr>
          </tbody>
        </Table>
      );
}