import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "react-router-dom";
import { FcSearch } from "react-icons/fc";
import CreateProjectForm from "./CreateProjectForm";
import { ApiUrl } from "../../utilities/getApiUrl";
import "./ProjectList.css"; // Import the CSS file

export default function ProjectsList() {
  const { getAccessTokenSilently } = useAuth0();
  const [searchText, setSearchText] = useState("");
  const [creatingProject, setCreatingProject] = useState(false);
  const [projects, setProjects] = useState([]); // Step 2: Initialize projects state

  useEffect(() => {
    const getProjectData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "gkplus",
            scope: "all",
          },
        });

        const response = await fetch(
          `${ApiUrl()}/api/project?searchText=${searchText}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const projectsData = await response.json();
        setProjects(projectsData); // Update projects state
      } catch (error) {
        console.error(error);
      }
    };

    getProjectData();
  }, [getAccessTokenSilently, searchText]);

  const addProject = (project) => {
    setProjects([...projects, project]); // Update projects state
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' }; // Show day, month, and year
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <Row className="search-row">
        <Col md={8}>
          <InputGroup className="m-3 search-group">
            <InputGroup.Text id="basic-addon1" className="search-icon">
              <FcSearch />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search Projects..."
              aria-label="Search Projects"
              aria-describedby="basic-addon1"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col md={4} className="d-flex justify-content-end">
          <Button
            variant={creatingProject ? "outline-danger" : "outline-success"}
            className="m-3"
            onClick={() => setCreatingProject(!creatingProject)}
          >
            {creatingProject ? "Close" : "Create Project"}
          </Button>
        </Col>
      </Row>
      <Collapse in={creatingProject}>
        <div className="m-3">
          <CreateProjectForm
            setCreatingProject={setCreatingProject}
            addProject={addProject}
            projects={projects} // Step 3: Pass projects state to CreateProjectForm
          />
        </div>
      </Collapse>
      <div className="table-responsive m-3">
        <Table striped bordered hover className="projects-table">
          <thead>
            <tr>
              <th className="name-column">Name</th>
              <th className="duration-column">Duration</th>
              <th className="notes-column">Notes</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.Id}>
                <td className="name-column">
                  <Link to={`project/${project.Id}`} className="project-link">
                    {project.Name}
                  </Link>
                </td>
                <td className="duration-column">{formatDate(project.StartDate)} - {formatDate(project.EndDate)}</td>
                <td className="notes-column">{project.Notes}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
