import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';

export default function Budget() {
  const { getAccessTokenSilently } = useAuth0();
  const [budgetData, setBudgetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    // Simulated fetching budget data
    const fetchBudgetData = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: "gkplus",
            scope: "all"
          },
        });
        console.log(accessToken);
        // Replace with actual fetch call when API is working
        // const response = await fetch(`https://turfy.pro/api/budget`, {
        //   headers: {
        //     Authorization: `Bearer ${accessToken}`,
        //   },
        //   method: "GET",
        // });
        // const data = await response.json();
        const data = [
          { id: 1, project: "Project A", name: "John Doe", type: "Labor", month: "January", amount: 1000 },
          { id: 2, project: "Project B", name: "Jane Smith", type: "Materials", month: "January", amount: 2000 },
          { id: 3, project: "Project C", name: "Bob Johnson", type: "Equipment", month: "February", amount: 1500 },
          { id: 4, project: "Project D", name: "Alice Williams", type: "Misc", month: "February", amount: 3000 },
          // Add more data for each month
        ];
        setBudgetData(data);
        setFilteredData(data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchBudgetData();
  }, [getAccessTokenSilently]);

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
    if (value) {
      setFilteredData(budgetData.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
    } else {
      setFilteredData(budgetData);
    }
  };

  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthlyData = months.map(month => {
    return {
      month,
      totalAmount: filteredData
        .filter(item => item.month === month)
        .reduce((sum, item) => sum + item.amount, 0)
    };
  });

  const lineData = {
    labels: monthlyData.map(item => item.month),
    datasets: [
      {
        label: 'Monthly Spending',
        data: monthlyData.map(item => item.totalAmount),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      },
    ],
  };

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2>Monthly Budget Breakdown</h2>
        </Col>
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Filter by name"
            value={filter}
            onChange={handleFilterChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Line data={lineData} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Month</th>
                <th>Project</th>
                <th>Name</th>
                <th>Type</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map(item => (
                <tr key={item.id}>
                  <td>{item.month}</td>
                  <td>{item.project}</td>
                  <td>{item.name}</td>
                  <td>{item.type}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}
