import Table from "react-bootstrap/Table";

export default function Inventory(props) {
  return (
    <div>
      <Table striped>
        <thead>
          <tr>
            <th>Item</th>
            <th>Amount Left</th>
            <th>Supplier</th>
            <th>Cost</th>
            <th>Last Restock</th>
            <th>Expires At</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Paint</td>
            <td>32 Gallons</td>
            <td>Paint Store</td>
            <td>$42 per gallon</td>
            <td>3/22/24</td>
            <td>3/22/26</td>
          </tr>
          <tr>
            <td>Seed</td>
            <td>80 Lbs</td>
            <td>Seed Store</td>
            <td>$12 per Lbs</td>
            <td>4/22/24</td>
            <td>3/22/25</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
