import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Collapse from "react-bootstrap/Collapse";
import Select from "react-select";
import { FaFilter, FaTasks } from "react-icons/fa";

// Mock data for demonstration purposes
const fakeTasksData = [
  { id: 1, name: "Task 1", project: "Project A", assignedTo: "", dueDate: "2024-05-28", status: "Pending" },
  { id: 2, name: "Task 2", project: "Project B", assignedTo: "", dueDate: "2024-06-15", status: "Pending" },
  { id: 3, name: "Task 3", project: "Project C", assignedTo: "", dueDate: "2024-07-02", status: "Pending" },
];

const fakeStaffData = [
  { value: "John Doe", label: "John Doe" },
  { value: "Jane Smith", label: "Jane Smith" },
];

export default function WorkOrderManagement() {
  const [tasks, setTasks] = useState(fakeTasksData);
  const [filterProject, setFilterProject] = useState("");
  const [staffOptions, setStaffOptions] = useState(fakeStaffData);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState([]);

  const handleCreateStaff = (inputValue) => {
    const newStaff = { value: inputValue, label: inputValue };
    setStaffOptions([...staffOptions, newStaff]);
    setSelectedStaff(newStaff);
  };

  const handleAssignTasks = (e) => {
    e.preventDefault();
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        selectedTasks.includes(task.id) ? { ...task, assignedTo: selectedStaff.label, status: "Assigned" } : task
      )
    );
    setSelectedTasks([]);
    setSelectedStaff(null);
  };

  const handleTaskSelection = (taskId) => {
    setSelectedTasks((prevSelected) =>
      prevSelected.includes(taskId)
        ? prevSelected.filter((id) => id !== taskId)
        : [...prevSelected, taskId]
    );
  };

  const handleSendWorkOrders = () => {
    // Implementation to send work orders to staff
    console.log("Sending work orders to staff...");
  };

  const filteredTasks = filterProject
    ? tasks.filter((task) => task.project === filterProject)
    : tasks;

  return (
    <Container fluid className="p-3">
      <Row className="mb-4 align-items-center">
        <Col md={6}>
          <h2><FaTasks className="me-2"/>Work Order Management</h2>
        </Col>
        <Col md={6}>
          <InputGroup>
            <InputGroup.Text><FaFilter /></InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Filter by Project"
              value={filterProject}
              onChange={(e) => setFilterProject(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Select</th>
                <th>Task Name</th>
                <th>Project</th>
                <th>Assigned To</th>
                <th>Due Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredTasks.map((task) => (
                <tr key={task.id}>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectedTasks.includes(task.id)}
                      onChange={() => handleTaskSelection(task.id)}
                    />
                  </td>
                  <td>{task.name}</td>
                  <td>{task.project}</td>
                  <td>{task.assignedTo}</td>
                  <td>{task.dueDate}</td>
                  <td>{task.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Collapse in={selectedTasks.length > 0}>
            <div className="mt-3">
              <Form onSubmit={handleAssignTasks}>
                <Row className="align-items-center">
                  <Col md={6}>
                    <Select
                      value={selectedStaff}
                      onChange={setSelectedStaff}
                      onCreateOption={handleCreateStaff}
                      options={staffOptions}
                      isClearable
                      placeholder="Select or create a staff member"
                      className="basic-single"
                      classNamePrefix="select"
                      formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                    />
                  </Col>
                  <Col md={2}>
                    <Button variant="primary" type="submit" disabled={!selectedStaff}>
                      Assign Tasks
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Collapse>
          <div className="mt-3">
            <Button variant="success" onClick={handleSendWorkOrders} disabled={false}>
              Send Work Orders
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
