import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { FcPrevious } from "react-icons/fc";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProjectTasksList from "./ProjectTasksList";
import ProjectLaborList from "./ProjectLaborList";
import ProjectInventoryList from "./ProjectInventoryList";
import ProjectBudgetList from "./ProjectBudgetList";
import "./ProjectEdit.css";

export default function ProjectsEdit() {
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState("tasks");

  useEffect(() => {
    const storedTab = localStorage.getItem("activeProjectEditTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
    localStorage.setItem("activeProjectEditTab", tabKey);
  };

  return (
    <>
      <Row className="header-row">
        <Col xs="auto">
          <Link to="/" className="back-link">
            <FcPrevious size={24} />
          </Link>
        </Col>
        <Col className="header-title">
          <h4>Project Name</h4>
        </Col>
        <Col xs="auto" className="project-id">
          <span>ID: {id}</span>
        </Col>
      </Row>
      <Row>
        <Tabs
          activeKey={activeTab}
          onSelect={handleTabChange}
          id="project-edit-tabs"
          className="mb-3"
        >
          <Tab eventKey="tasks" title="Tasks">
            <ProjectTasksList />
          </Tab>
          <Tab eventKey="labor" title="Labor">
            <ProjectLaborList />
          </Tab>
          <Tab eventKey="inventory" title="Inventory">
            <ProjectInventoryList />
          </Tab>
          <Tab eventKey="budget" title="Budget">
            <ProjectBudgetList />
          </Tab>
        </Tabs>
      </Row>
    </>
  );
}
