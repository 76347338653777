import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { ApiUrl } from "../../utilities/getApiUrl";
import Collapse from "react-bootstrap/Collapse";
import { useParams } from "react-router-dom";

export default function CreateBudgetForm(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState(0);

  let { id } = useParams();
  const saveChanges = async () => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: "gkplus",
        scope: "all",
      },
    });
    await fetch(`${ApiUrl()}/api/budget`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        projectId: id,
        name,
        type,
        amount,
      }),
    })
      .then(async (res) => {
        const parsedRes = await res.json();
        console.log(parsedRes)
        props.addBudget({
            id: parsedRes.Id,
            name: name,
            type: type,
            amount: amount,
        });
        setFormOpen(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Button
        variant="success"
        className="m-3"
        onClick={() => setFormOpen(true)}
      >
        Create Budget
      </Button>
      <Collapse in={formOpen}>
        <div>
          <Form className="m-3">
            <Row>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter budget name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter budget type"
                    onChange={(e) => setType(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group as={Col}>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter budget amount"
                    onChange={(e) => setAmount(Number(e.target.value))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button onClick={saveChanges}>Save Budget</Button>
                <Button
                  variant="danger"
                  className="m-3"
                  onClick={() => setFormOpen(false)}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Collapse>
    </>
  );
}