import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import UserInfo from "./pages/user/UserInfo";
import ProjectsList from "./pages/project/ProjectList";
import ProjectsEdit from "./pages/project/ProjectEdit";
import Budget from "./pages/budget/Budget";
import Tasks from "./pages/tasks/Tasks";
import Staff from "./pages/staff/Staff";
import Inventory from "./pages/inventory/Inventory";

function App() {
  const { isLoading, loginWithRedirect, user, logout } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        await loginWithRedirect();
      }
    })();
  }, [isLoading, user, loginWithRedirect]);

  const api_regex = /^\/api\/.*/;

  if (api_regex.test(window.location.pathname)) {
    return <></>;
  } else {
    return (
      <Router>
        <div className="App">
          <Navbar className="bg-body-tertiary navbar">
            <Container>
              <Navbar.Brand href="/">FieldManager Pro</Navbar.Brand>
              <UserInfo />
              <Button
                type="submit"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                Logout
              </Button>
            </Container>
          </Navbar>
          <div className="sideNav">
            <Nav defaultActiveKey="/" className="flex-column">
              <Nav.Link as={Link} to="/" className="sideNavLink">Projects</Nav.Link>
              <Nav.Link as={Link} to="/tasks" className="sideNavLink">Tasks</Nav.Link>
              <Nav.Link as={Link} to="/budget" className="sideNavLink">Budget</Nav.Link>
              <Nav.Link as={Link} to="/inventory" className="sideNavLink">Inventory</Nav.Link>
              <Nav.Link as={Link} to="/equipment" className="sideNavLink">Equipment</Nav.Link>
              <Nav.Link as={Link} to="/staff" className="sideNavLink">Staff</Nav.Link>
            </Nav>
          </div>
          <div className="content">
            <Switch>
              <Route exact path="/">
                <ProjectsList />
              </Route>
              <Route path="/project/:id">
                <ProjectsEdit />
              </Route>
              <Route path="/budget">
                <Budget />
              </Route>
              <Route path="/tasks">
                <Tasks />
              </Route>
              <Route path="/inventory">
                <Inventory  />
              </Route>
              <Route path="/staff">
                <Staff />
              </Route>
              {/* Add other routes as needed */}
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
