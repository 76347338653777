import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { ApiUrl } from "../../utilities/getApiUrl";

export default function ProjectLaborList() {
  const { getAccessTokenSilently } = useAuth0();
  const { id } = useParams(); // Get projectId from URL params
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [hoursWorked, setHoursWorked] = useState("");
  const [laborList, setLaborList] = useState([]); // State to hold labor list

  const fetchLaborList = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${ApiUrl()}/api/labor?projectId=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch labor list");
      }

      const data = await response.json();
      setLaborList(data);
    } catch (error) {
      console.error("Error fetching labor list:", error.message);
    }
  };

  useEffect(() => {
    fetchLaborList();
  }, [id, getAccessTokenSilently]);

  const handleCreateLabor = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      console.log(hoursWorked)
      const laborData = {
        projectId: id, // Include projectId in the data
        name,
        type,
        hours: parseInt(hoursWorked),
      };

      const response = await fetch(`${ApiUrl()}/api/labor`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(laborData),
      });

      if (!response.ok) {
        throw new Error("Failed to create labor item");
      }

      setName("");
      setType("");
      setHoursWorked("");
      setShowForm(false);

      fetchLaborList(); // Fetch updated list after creating a new labor item
    } catch (error) {
      console.error("Error creating labor item:", error.message);
    }
  };

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)}>
        {showForm ? "Hide Form" : "Create New Labor"}
      </Button>
      {showForm && (
        <Form>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formType">
            <Form.Label>Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formHoursWorked">
            <Form.Label>Hours Worked</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter hours worked"
              value={hoursWorked}
              onChange={(e) => setHoursWorked(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" onClick={handleCreateLabor}>
            Create Labor
          </Button>
        </Form>
      )}

      {/* Labor list table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Hours Worked</th>
          </tr>
        </thead>
        <tbody>
          {laborList.map((laborItem) => (
            <tr key={laborItem.id}>
              <td>{laborItem.name}</td>
              <td>{laborItem.type}</td>
              <td>{laborItem.hoursWorked}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
